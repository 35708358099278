import Layout from "../Layout";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {apiGetPost} from "../../lib/BlogApi";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {isArray} from "lodash";

export default function Blogpost(){
    let params = useParams();
    const [post, setPost] = useState({})
    const supabase = useSupabaseClient()
    useEffect(() => {
        async function fetchPost(){
            var post = await apiGetPost(supabase,Number(params.id))
            setPost(post)
        }
        fetchPost()
    },[])
    return (
        <Layout>
            <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
                <div className="mb-4 md:mb-0 w-full mx-auto relative">
                    <div className="px-4 lg:px-0">
                        <h2 className="mt-10 text-4xl font-semibold text-text-important leading-tight">
                            {post.title}
                        </h2>
                    </div>
                    <img src="http://taskz.io/blog_bg_full.png" className="w-full object-cover lg:rounded" />
                </div>
                <div className="flex flex-col lg:flex-row lg:space-x-12">
                    <div className="px-4 lg:px-0 mt-12 text-text-bg text-lg leading-relaxed w-full lg:w-3/4">

                            {post.content !== undefined && post.content.intro.map((intro)=> (
                                <p className="mt-2 text-text-bg">{intro}</p>
                                ))}

                            {post.content !== undefined && post.content.content.map((para) => (
                                <div className="mt-4">
                                    <span className="mt-8 text-text-important/60 font-semibold">{para.title}</span>
                                    {isArray(para.content) && para.content.map((c) => (
                                             <p className="mt-4 text-text-bg">{c}</p>
                                            ))}
                                    {!isArray(para.content) &&
                                     <p className="mt-4 text-text-bg">{para.content}</p>
                                    }
                                    { para.content_list !== undefined &&
                                        <ul className="list-disc mt-4">
                                            {para.content_list.map((list) => (
                                                <li className="mt-4 text-text-bg">{list}</li>
                                            ))}
                                        </ul>
                                    }
                                    {para.content_numbered !== undefined &&
                                        <ol className="mt-4 text-text-bg">
                                            {para.content_numbered.map((list) => (
                                                <li className="mt-4 text-text-bg"><span className="font-semibold text-text-important/60">{list.title}</span>{list.content}</li>
                                            ))}
                                        </ol>
                                    }


                                </div>

                            ))}




                    </div>

                    <div className="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
                        <div className="p-4 border-t border-b md:border md:rounded">
                            <div className="flex py-2">
                                <img src={"http://taskz.io/"+post.author_image}
                                     className="h-10 w-10 rounded-full mr-2 object-cover"/>
                                <div>
                                    <p className="font-semibold text-text-bg text-sm"> {post.author_name} </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}
