

import React from 'react';

const team = [


    {
        name: 'Liesbeth Verwerft',
        role: 'Founder, developer',
        imageUrl:
            'LiesbethV.png',
    },
    // More people...
]

function About_team() {
    return(
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8 items-center justify-center">
            <div className="mx-auto lg:mx-0 text-center">
                <h2 className="text-3xl font-bold tracking-tight text-text-important sm:text-4xl">Our team</h2>
                <p className="mt-6 text-lg leading-8 text-text-bg">
                    Passionate developers committed to making Taskz the best it can be, for you.
                </p>
            </div>
            <ul
                role="list"
                className="mx-auto mt-20 max-w-2xl  text-center  lg:mx-0 lg:max-w-none flex items-center justify-center gap-x-6"
            >
                {team.map((person) => (
                    <li key={person.name}>
                        <img className="mx-auto h-24 w-24 rounded-full" src={person.imageUrl} alt="" />
                        <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-text-bg">{person.name}</h3>
                        <p className="text-sm leading-6 text-text-bg/80">{person.role}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}
export default About_team;
