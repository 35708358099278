
import React from 'react';

export default function Legal_content() {
    return(

        <section className="">
            <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
                <div className="max-w-3xl mx-auto text-center">
                    <h1 className="text-3xl font-extrabold tracking-tight text-text-important sm:text-4xl">
                        Terms and Conditions
                    </h1>
                    <p className="mt-4 text-base text-text-faded sm:text-xl">
                        Last updated on January 29, 2022
                    </p>
                </div>

                <div className="max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16">
                    <h3 className="mt-8 text-2xl font-bold text-text-important">
                        Welcome to Taskz
                    </h3>
                    <p className="mt-6 text-base font-normal text-text-bg">
                        These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Crafty Dwarf LLC ("Company", “we”, “us”, or “our”), concerning your access to and use of the https://themesberg.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). The Site provides an online marketplace for the following goods, products, and/or services: website themes and templates (the “Marketplace Offerings”). In order to help make the Site a secure environment for the purchase and sale of Marketplace Offerings, all users are required to accept and comply with these Terms of Use.
                    </p>
                    <p className="mt-4 text-base font-normal text-text-bg">
                        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason.
                    </p>
                    <p className="mt-4 text-base font-normal text-text-bg">
                        The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                    </p>

                    <h3 className="mt-8 text-2xl font-bold text-text-bg">
                        How buying items works
                    </h3>
                    <div className="p-6 mt-6 rounded-lg bg-card-bg">
                        <p className="text-base font-normal text-text-bg">
                            This section will help you understand what you are buying when you purchase an item and how that transaction takes place on Flowbite Library.
                        </p>
                    </div>

                    <h4 className="mt-4 text-xl font-bold text-text-bg">
                        1. What you're buying:
                    </h4>
                    <ol
                        className="mt-4 text-base font-normal text-text-bg space-y-2.5 list-[lower-alpha] list-outside pl-5">
                        <li>
                            <span className="font-semibold text-text-bg">License:</span> When you buy an item, you acquire the right to use that item; you're not actually acquiring the item itself. What you get includes a license directly from the author to use that item. Items are subject to specific terms of use,
                            and these terms are the ‘license’ that we set on Flowbite. This license also applies to you if you download an item that someone else has bought for you (because anyone downloading an item needs to be an Flowbite member). Different license types are available for you to choose when you have selected an item. You’ll need to think about the way you want to use the item so that you can pick the right license to allow that use. It’s your responsibility to choose the correct license.
                        </li>

                        <li>
                            <span className="font-semibold text-text-bg">Item support:</span> Authors can choose whether or not to support certain items. If an author chooses to support an item, this will be identified on the item page. All supported items include a support period. You can buy support extensions on these items. Your right to access Item Support requires an Flowbite account.
                        </li>

                        <li>
                            <span className="font-semibold text-text-bg">Buyer Services:</span> In addition to the use of the platform, when you ‘buy’ an item you also receive buyer services from Flowbite like 24/7 buyer support, fraud protection, item quality control and other related buyer services.
                        </li>
                    </ol>

                    <h4 className="mt-4 text-xl font-bold text-text-bg">
                        2. The total price for an item on Taskz Market is made up of:
                    </h4>
                    <ol
                        className="mt-4 text-base font-normal text-text-bg space-y-2.5 list-[lower-alpha] list-outside pl-5">
                        <li>
                            <span className="font-semibold text-text-bg">Item price:</span> The item price is made up of a license fee (for the license you choose for the item), and if relevant the item support fee (for supported items).
                        </li>

                        <li>
                            <span className="font-semibold text-text-bg">Buyer fee:</span> This is the fee for the buyer services you get from Envato.
                        </li>

                        <li>
                            <span className="font-semibold text-text-bg">Handling fee:</span> In some transactions on Flowbite Market the total checkout price may include a handling fee.
                        </li>

                        <li>
                            <span className="font-semibold text-text-bg">Taxes:</span> Some transactions on Flowbite Market may be subject to tax that may be added to the price. See section 23 for details about taxes on Flowbite Market.
                        </li>
                    </ol>
                </div>
            </div>
        </section>

    )
}
