import { createSlice } from '@reduxjs/toolkit'

export const ThemeSlice = createSlice({
    name: 'theme',
    initialState: {
        value:  window.matchMedia("(prefers-color-scheme: dark)").matches?'dark':'light'
    },
    reducers: {
        toggleTheme: (state) => {
            if (state.value === 'light') {
                state.value = 'dark';
            } else {
                state.value = 'light'
            }
        },
    }

})
export const {toggleTheme} = ThemeSlice.actions;

export default ThemeSlice.reducer
