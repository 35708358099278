import React from 'react';

import { LuEye, LuScroll, LuCalendarDays, LuBrush } from "react-icons/lu";

const features = [


    {
        name: 'Do it your way',
        description:
            'Customize the app to fit your workflow, not the other way around.',
        icon: LuBrush,
    },
    {
        name: 'Be the hero in your own story',
        description:
            'Save the world from the evil magic that plagues it, 1 quest at a time.',
        icon: LuScroll,
    },
    {
        name: 'Take time to plan',
        description:
            'Think about what you want to achieve and how you can get there before you start.',
        icon: LuCalendarDays,
    },
    {
        name: 'Be mindful of your workload',
        description:
            'Visualize your tasks and plan your day with our intuitive planner and workload tracker.',
        icon: LuEye,
    },
]
function Feature_grid(){
    return (
        <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-chip-bg">Do more</h2>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-text-important sm:text-4xl">
                        Everything you need to stay motivated
                    </p>
                    <p className="mt-6 text-lg leading-8 text-text-bg">

                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-text-important">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-chip-bg">
                                        <feature.icon className="h-6 w-6 text-chip-text" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-text-bg">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default Feature_grid;

