import Layout from "../Layout";
import Legal_content from "./Legal_content";

export default function Legal(){
    return (
        <Layout>
            <Legal_content />
        </Layout>
    );
}
