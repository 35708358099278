import Layout from "../Layout";
import Landing_cta from "./Landing_cta";
import Feature_right from "./Feature_right";
import Feature_left from "./Feature_left";
import Feature_grid from "./Feature_grid";
import Testimonials from "./Testimonials";
import CTA_end from "./CTA_end";

export default function Landing(){
    return (
        <Layout>
            <Landing_cta />
            <section id="features">
                <Feature_right num={1}/>
                <Feature_left />
                <Feature_right num={2} />
                <Feature_grid />
            </section>

            <CTA_end />
        </Layout>
    );
}

// <Testimonials />
