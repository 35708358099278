import React from 'react';
import { LuMail} from 'react-icons/lu'
function About_contact() {
    return(
        <div className="relative mt-32">
            <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2" />
            </div>
            <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
                <div className="bg-card-bg px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 rounded-2xl">
                    <div className="mx-auto max-w-lg">
                        <h2 className="text-2xl font-bold tracking-tight text-text-important sm:text-3xl">Get in touch</h2>
                        <p className="mt-3 text-lg leading-6 text-text-bg">

                        </p>
                        <dl className="mt-8 text-base text-text-bg">
                            <div>

                            </div>
                            <div className="mt-6">

                            </div>
                            <div className="mt-3">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex">
                                    <LuMail className="h-6 w-6 flex-shrink-0 text-text-bg" aria-hidden="true" />
                                    <span className="ml-3">contact@taskz.io</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className=" px-6 py-16 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
                    <div className="mx-auto max-w-lg lg:max-w-none">
                        <form action="mailto:contact@taskz.io" method="POST" className="grid grid-cols-1 gap-y-6">
                            <div>
                                <label htmlFor="full-name" className="sr-only">
                                    Full name
                                </label>
                                <input
                                    type="text"
                                    name="full-name"
                                    id="full-name"
                                    autoComplete="name"
                                    className="block w-full bg-input-bg text-text-important rounded-md border-text-bg px-4 py-3 placeholder:text-text-bg/50 shadow-sm focus:border-chip-bg focus:ring-chip-bg"
                                    placeholder="Full name"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full bg-input-bg text-text-important rounded-md border-text-bg px-4 py-3 placeholder:text-text-bg/50 shadow-sm focus:border-chip-bg focus:ring-chip-bg"
                                    placeholder="Email"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="sr-only">
                                    Phone
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    autoComplete="tel"
                                    className="block w-full bg-input-bg text-text-important rounded-md border-text-bg px-4 py-3 placeholder:text-text-bg/50 shadow-sm focus:border-chip-bg focus:ring-chip-bg"
                                    placeholder="Phone"
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="sr-only">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={4}
                                    className="block w-full bg-input-bg text-text-important rounded-md border-text-bg px-4 py-3 placeholder:text-text-bg/50 shadow-sm focus:border-chip-bg focus:ring-chip-bg"
                                    placeholder="Message"
                                    defaultValue={''}
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-chip-bg px-6 py-3 text-base font-medium text-chip-text shadow-sm hover:bg-chip-accent focus:outline-none focus:ring-2 focus:ring-chip-bg focus:ring-offset-2"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About_contact;
