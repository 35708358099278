import React from 'react';
const app_url = 'https://app.taskz.io'

function CTA_end(){
    return(
        <div className="">
            <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-text-important sm:text-4xl">
                        Boost your productivity.
                        <br />
                        Start using Taskz.
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-text-bg">
                        The digital planner that rewards you for planning and completing your tasks.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href={app_url + "/register"}
                            className="rounded-md bg-chip-bg text-chip-text px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-chip-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
                        >
                            Get started
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA_end;

