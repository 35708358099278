

import React from 'react';


const values = [


    {
        name: 'Reduce stress and overwhelm',
        description:
            'We aim to help people to be more productive and to get things done, without stress and overwhelm getting in the way. ',
    },
    {
        name: 'Achieve long-time goals',
        description:
            'We all have dreams we want to follow, but staying on track is often the hardest part. We believe everyone can achieve their goal with the right tools and support. ',
    },
    {
        name: 'Stay motivated',
        description:
            'Motivation is key to getting things done. We believe in helping you stay motivated and focused on your goals, so you can achieve more.',
    },
    {
        name: 'Be supportive',
        description:
            'Getting things done is easier when you have a supportive community around you. We believe in helping each other and lifting each other up.',
    },
    {
        name: 'Have fun along the way',
        description:
            'Productivity doesn’t have to be boring. We believe in making productivity fun and rewarding, so you can enjoy the journey as much as the destination.',
    },
    {
        name: 'Enjoy downtime',
        description:
            'Productivity is not all that matters - your downtime is just as important. We believe in taking breaks and enjoying life, so you can come back refreshed and ready to tackle your tasks.'
    },
]


function Mission_statement_values() {
    return(
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-text-important sm:text-4xl">Our values</h2>
                <p className="mt-6 text-lg leading-8 text-text-bg">
                    Being productive and getting things done is important, but not always easy. We believe in a different approach to productivity that focuses on the individual, their unique needs, and motivation. Our values are the foundation of our work and the reason we do what we do.
                </p>
            </div>
            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {values.map((value) => (
                    <div key={value.name}>
                        <dt className="font-semibold text-text-bg">{value.name}</dt>
                        <dd className="mt-1 text-text-bg">{value.description}</dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
export default Mission_statement_values;
