import React from 'react';
import {useSelector} from "react-redux";

function Feature_right({num}){
    const theme = useSelector(store => store.theme.value)
    return (
        <div className="overflow-hidden py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                    <div className="lg:pr-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-chip-bg">Do more</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-text-important sm:text-4xl">{num === 1? "Make it fun":"Be challenged"}</p>
                            {num === 1 && <p className="mt-6 text-lg leading-8 text-text-bg">
                                Get fun rewards for completing your tasks. The more you plan and complete, the more rewards you get.
                            </p>}
                            {num === 2 && <p className="mt-6 text-lg leading-8 text-text-bg">
                                We'll provide challenging quests and battles to help you stay motivated and focused.
                            </p>}

                        </div>
                    </div>
                    {num === 1 && theme === "light" &&
                        <img src="companions_prt_light.png" alt="Companions screenshot" className="w-[37rem] max-w-none rounded-xl shadow-xl ring-1 ring-text-bg/80 sm:w-[37rem] md:-ml-4 lg:ml-0" width={912} height={540}/>
                    }
                    {num === 1 && theme === "dark" &&
                        <img src="companions_prt.png" alt="Companions screenshot" className="w-[37rem] max-w-none rounded-xl shadow-xl ring-1 ring-text-bg/80 sm:w-[37rem] md:-ml-4 lg:ml-0" width={912} height={540}/>
                    }
                    {num === 2 && theme === "light" &&
                        <img src="combat_prt_light.png" alt="Combat screenshot" className="w-[37rem] max-w-none rounded-xl shadow-xl ring-1 ring-text-bg/80 sm:w-[37rem] md:-ml-4 lg:ml-0" width={912} height={540}/>
                    }
                    {num === 2 && theme === "dark" &&
                        <img src="combat_prt.png" alt="Combat screenshot" className="w-[37rem] max-w-none rounded-xl shadow-xl ring-1 ring-text-bg/80 sm:w-[37rem] md:-ml-4 lg:ml-0" width={912} height={540}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Feature_right;

