import React from 'react';

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {LuMenu, LuX} from "react-icons/lu";
import {useSelector} from "react-redux";

const base_url = 'https://www.taskz.io'

const app_url = 'https://www.app.taskz.io'
function joinUrl(...urls) {
    return urls.join('')
}

const navigation = [


    { name: 'Features', href: base_url+'#features' },
    //{ name: 'Pricing', href: 'pricing' },
    { name: 'About', href: base_url+'/about' },
    { name: 'Blog', href: base_url+'/blog' },
]

function Nav() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const theme = useSelector(store => store.theme.value)
    return (
        <header className="">
            <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href={base_url} className="-m-1.5 p-1.5">
                        <span className="sr-only">Taskz</span>
                        {theme === 'light' && <img className="h-10 w-auto" src={base_url+"/Logo_dark_250x50.png"} alt="" /> }
                        {theme === 'dark' && <img className="h-10 w-auto" src={base_url+"/Logo Light250x50.png"} alt="" /> }
                    </a>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-text-bg hover:text-chip-bg">
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="flex flex-1 items-center justify-end gap-x-6">
                    <a href={joinUrl(app_url,'/login')} className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-text-bg hover:lg:text-chip-bg">
                        Log in
                    </a>
                    <a
                        href={joinUrl(app_url,'/register')}
                        className="rounded-md bg-chip-bg text-chip-text px-3 py-2 text-sm font-semibold shadow-sm hover:bg-chip-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
                    >
                        Sign up
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-text-bg"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <LuMenu className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-card-bg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-text-bg/10">
                    <div className="flex items-center gap-x-6">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Taskz</span>
                            <img
                                className="h-8 w-auto"
                                src="Icon_32x32.png"
                                alt="Taskz"
                            />
                        </a>
                        <a
                            href={joinUrl(app_url,'/register')}
                            className="ml-auto rounded-md bg-chip-bg text-chip-text px-3 py-2 text-sm font-semibold shadow-sm hover:bg-chip-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chip-bg"
                        >
                            Sign up
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-text-bg"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <LuX className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-text-bg/80">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-bg hover:text-chip-bg"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href={joinUrl(app_url,'/login')}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-text-bg hover:text-chip-bg"
                                >
                                    Log in
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}



export default Nav;
