import Layout from "../Layout";
import Mission_statement_with_image from "./Mission_statement_with_image";
import Mission_statement_values from "./Mission_statement_values";
import About_team from "./About_team";
import About_blog from "./About_blog";
import About_contact from "./About_contact";
import Mission_statement from "./Mission_statement";

export default function About(){
    return (
        <Layout>
            <Mission_statement_with_image />
            <Mission_statement_values />
            <About_team />
            <About_blog />
            <About_contact />
        </Layout>
    );
}
