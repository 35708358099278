'use client'

import { ThemeProvider } from 'next-themes'
import {useSelector} from "react-redux";

export const Theme = ({ children }) => {
    const theme=useSelector(state => state.theme.value)
    return (
        <ThemeProvider attribute="class">
            <div className={theme}>
                {children}
            </div>
        </ThemeProvider>
    );
};
