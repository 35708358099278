

export async function apiGetFeaturedPosts(supabase){

    const {data:posts,error} = await supabase.from('Blogposts').select('*').or('featured.eq.true,hero.eq.true').limit(3).order('hero',{ascending: false}).order('created_at', { ascending: false })
    if(!error){
        return posts
    }
    return []
}

export async function apiGetPosts(supabase){
    const {data:posts,error} = await supabase.from('Blogposts').select('*').order('hero',{ascending: false}).order('featured',{ascending: false}).order('created_at', { ascending: false })
    if(!error){
        return posts
    }
    return []
}

export async function apiGetHero(supabase){
    const {data:hero,error} = await supabase.from('Blogposts').select('*').eq('hero',true)
    if(!error){
        return hero
    }
    return []
}

export async function apiGetFeatured(supabase){
    const {data:featured,error} = await supabase.from('Blogposts').select('*').eq('featured',true).order('created_at', { ascending: false })
    if(!error){
        return featured
    }
    return []
}

export async function apiGetPost(supabase,id){
    const {data:post,error} = await supabase.from('Blogposts').select('*').eq('id',id)
    if(!error){
        return post[0]
    }
    return {}
}

export async function apiSubscribeNewsletter(supabase,email){
    const {data:result,error} = await supabase.from('Newsletter').insert({email:email}).select()
    if(!error){
        return {result:result,error:false}
    }
   return {result:false,error:error}
}
