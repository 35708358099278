import {combineReducers, configureStore} from '@reduxjs/toolkit'
import themeReducer from './ThemeSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const persistConfig = {
    key: 'taskz_web',
    storage,
    stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({
    theme: themeReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store =  configureStore({
    reducer: persistedReducer,
})


export const persistor = persistStore(store)
