import React from "react";
import Nav from "./Navigation/Nav";
import Footer from "./Navigation/Footer";


export default function Layout({ children }) {

    return (
        <div className="bg-gradient">
            <Nav/>
             {children}
            <Footer/>
        </div>
    );
}

