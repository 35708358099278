import React from 'react';
import {useSelector} from "react-redux";
const app_url = 'https://app.taskz.io'
function Landing_cta(){
    const theme = useSelector(store => store.theme.value)
    return (
        <div className="">
            <div className="relative isolate pt-14">
                <div className="py-24 sm:py-32 lg:pb-40">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-text-important sm:text-6xl">
                                Boost your motivation. <br></br>
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-text-bg">
                                The digital planner that rewards you for planning and completing your tasks.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a href={app_url + "/register"}
                                   className="rounded-md bg-chip-bg px-3.5 text-chip-text py-2.5 text-s font-semibold shadow-sm hover:bg-chip-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                >
                                    Get started
                                </a>
                                <a href="#features" className="text-sm font-semibold leading-6 text-text-important hover:text-chip-bg">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 flow-root sm:mt-24">
                            <div className="-m-2 rounded-xl bg-text-bg/5 p-2 ring-1 ring-inset ring-text-bg/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                                {theme === "light" && <img src="Frontpage_prt_light.png" alt="App screenshot" width={2432} height={1442} className="rounded-md shadow-2xl ring-1 ring-text-bg/10"/>}
                                {theme === "dark" && <img src="Frontpage_prt.png" alt="App screenshot" width={2432} height={1442} className="rounded-md shadow-2xl ring-1 ring-text-bg/10"/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Landing_cta;
