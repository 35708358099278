
import React from 'react';
import Layout from "../Layout";

export default function Privacy() {
    return(
        <Layout>
            <section className="">
                <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
                    <div className="max-w-3xl mx-auto text-center">
                        <h1 className="text-3xl font-extrabold tracking-tight text-text-important sm:text-4xl">
                            Privacy Policy
                        </h1>
                        <p className="mt-4 text-base text-text-important/60 sm:text-xl">
                            Last updated April 16, 2024
                        </p>
                    </div>

                    <div className="max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16">
                        <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                            This privacy notice for Taskz ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>") explains how we might collect, store, use, and/or share ("<strong>process</strong>")
                            your information when you use our services ("<strong>Services</strong>"), such as when you visit our website, create an account, or contact us.
                        </p>
                        <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                            <strong className="text-text-important/60">Questions or concerns?</strong> Reading this privacy policy will help you understand your privacy rights and choices. If you do not agree with our
                            policies and practices, please do not use our Services.
                        </p>

                        <h3 className="mt-8 text-2xl font-bold text-text-important">
                            SUMMARY OF KEY POINTS
                        </h3>
                        <p  >
                            <strong className="text-text-important/60 whitespace-normal">This summary provides key points from our privacy notice, but you can find out more details about any of
                            these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</strong>
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">What personal information do we process?</strong> When you visit, use, or navigate our Services, we may
                            process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. <a href="#information">Learn more about personal information you disclose to us.</a>
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">Do we receive any information from third parties?</strong> We do not use third-party services to
                            collect personal information about you.
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">How do we process your information? </strong> We process your information to provide, improve,
                            and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other
                            purposes with your consent. We process your information only when we have a valid legal reason to do so. <a href="#processing">Learn more about how we process your information..</a>
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">In what situations and with which parties do we share personal information?</strong> We may share your
                            information in specific situations and with specific third parties. <a href="#sharing">Learn more about when and with whom we share your personal information.</a>
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">What are your rights?</strong> Depending on where you are located geographically, the applicable privacy
                            law may mean you have certain rights regarding your personal information. <a href="#privacy_rights">Learn more about your privacy rights.</a>
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            <strong className="text-text-important/60 whitespace-normal">How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a data
                            subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </p>
                        <p className="mt-6 text-base font-normal text-text-bg">
                            Want to learn more about what we do with any information we collect? Review the privacy notice in full.
                        </p>
                        <h3 className="mt-8 text-xl font-bold text-text-important">
                            TABLE OF CONTENTS
                        </h3>
                        <ol className="text-text-important/80">
                            <li><a href="#information">1. WHAT INFORMATION DO WE COLLECT</a></li>
                            <li><a href="#processing">2. HOW DO WE PROCESS YOUR INFORMATION</a></li>
                            <li><a href="#sharing">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                            <li><a href="#tracking">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                            <li><a href="#social">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
                            <li><a href="#data_transfer">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></li>
                            <li><a href="#how_long_do_we_keep_your_information">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                            <li><a href="#information_from_minors">8. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                            <li><a href="#privacy_rights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                            <li><a href="#do_not_track_features">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                            <li><a href="#updates">11. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                            <li><a href="#contact">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                            <li><a href="#review">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                        </ol>
                        <div id="information">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                1. WHAT INFORMATION DO WE COLLECT
                            </h3>
                            <h5 className="mt-4 text-xl font-bold text-text-important">
                                Personal information you disclose to us
                            </h5>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We collect personal information that you provide to us.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services or otherwise when you contact us.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">Sensitive Information.</strong> We do not process sensitive information
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                            </p>
                            <h5 className="mt-4 text-xl font-bold text-text-important">
                                Information automatically collected
                            </h5>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                            </p>
                        </div>
                        <div id="processing">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                2. HOW DO WE PROCESS YOUR INFORMATION
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong>  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                            </p>
                        </div>
                        <div id="sharing">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We may share your information in specific situations described in this section and/or with the following third parties.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <ul className="list-disc">
                                    <li className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                        <strong className="text-text-important/60">Business Transfers</strong>  We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets,
                                        financing, or acquisition of all or a portion of our business to another company.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div id="tracking">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We do not use cookies and other tracking technologies to collect and store your information.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                The only cookies used by our application are cookies that are strictly necessary for the operation of our application.
                            </p>
                        </div>
                        <div id="social">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> If you choose to register or log in to our services using a social media account, we may have access to certain information about you.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                Our Services offer you the ability to register and login using your third-party social media account details (like your Google login). Where you choose to do this,
                                we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address,
                                profile picture as well as other information you choose to make public on such social media platform.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your
                                third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                               Taskz' use and transfer of information received from Google API's will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-text-important/70 hover:text-text-important font-semibold">Google API Services User Data Policy</a>, including the Limited Use requirements
                            </p>
                        </div>
                        <div id="data_transfer">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We may transfer, store, and process your information in countries other than your own.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                Our servers are located in Germany. If you are accessing our Services from outside Germany, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "<a href="#sharing">When and with whom do we share your personal information?</a>") in Germany and other countries.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
                            </p>
                        </div>
                        <div id="how_long_do_we_keep_your_information">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                7. HOW LONG DO WE KEEP YOUR INFORMATION?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                            </p>
                        </div>
                        <div id="information_from_minors">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                8. DO WE COLLECT INFORMATION FROM MINORS?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> We do not knowingly collect data from or market to children under 13 years of age.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                We do not knowingly solicit data from or market to children under 13 years of age. By using the Services, you represent that you are at least 13 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 13 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 13, please contact us at <a href="mailto:info@taskz.io">info@taskz.io</a>
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                The Services are intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in which they reside (generally
                                under the age of 18) must have permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor,
                                you must have your parent or guardian read and agree to this privacy policy prior to you using the Services.
                            </p>
                        </div>
                        <div id="privacy_rights">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                9. WHAT ARE YOUR PRIVACY RIGHTS?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> You may review, change, or terminate your account at any time.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                <strong className="text-text-important/60">Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express
                                and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time.
                                You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                            </p>
                            <h5 className="mt-4 text-xl font-bold text-text-important">
                                Account Information
                            </h5>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                If you would at any time like to review or change the information in your account or terminate your account, you can: <br></br>
                                Upon your request to terminate your account, we will deactivate and delete your account and information from our active databases. However, we may retain some information in our files to
                                prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
                            </p>
                        </div>
                        <div id="do_not_track_features">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                10. CONTROLS FOR DO-NOT-TRACK FEATURES
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal
                                your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and
                                implementing DNT signals has been finalized.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg whitespace-normal">
                                As we do not make use of any tracking technologies, we do not respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
                            </p>
                        </div>
                        <div id="updates">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                11. DO WE MAKE UPDATES TO THIS NOTICE?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                <strong className="text-text-important/60">In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                            </p>
                        </div>
                        <div id="contact">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                If you have questions or comments about this notice, you may email us at <a href="mailto:info@taskz.io">info@taskz.io</a>
                            </p>
                        </div>
                        <div id="review">
                            <h3 className="mt-8 text-2xl font-bold text-text-important">
                                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                            </h3>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances.
                            </p>
                            <p className="mt-4 text-base font-normal text-text-bg">
                                You can request to review, update or delete your personal information using the form provided in the 'Account' section of the application section when you are logged into the Services.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>

    )
}
