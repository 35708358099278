import React, {useEffect, useState} from 'react';
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetPosts} from "../../lib/BlogApi";
import {format} from "date-fns";
function Blog_detail_list() {
    const [posts, setPosts] = useState([])
    const supabase = useSupabaseClient()
    useEffect(() => {
        async function fetchBlog(){
            var posts = await apiGetPosts(supabase)
            setPosts(posts)
        }
        fetchBlog()
    },[])
    return(
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:pb-16">
            <div className="flex flex-col gap-12 lg:gap-20 md:flex-row md:items-start">
                <div className="flex-1 min-w-0">

                    <div className="mt-8 space-y-12 sm:mt-12">
                        {posts.map((post) => (
                            <div className="flex flex-col items-start gap-8 xl:flex-row">

                                <div className="space-y-4">

                                    <h2 className="text-2xl font-bold leading-tight tracking-tight text-text-bg dark:text-white">
                                        <a href={"blog/post/"+post.id} className="hover:underline" title="">
                                            {post.title}
                                        </a>
                                    </h2>

                                    <div className="flex items-center gap-3">
                                        <img className="w-8 h-8 rounded-full"
                                             src={post.author_image} alt=""/>
                                            <div
                                                className="text-lg font-medium leading-tight text-text-bg">
                                                <div>
                                                    {post.author_name}
                                                </div>
                                                <div className="text-sm font-normal text-text-bg/80">
                                                    {format(new Date(post.created_at),"MMMM dd, yyyy")}
                                                </div>
                                            </div>
                                    </div>

                                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                        {post.description}
                                    </p>
                                        <a href={"blog/post/"+post.id} title=""
                                           className="inline-flex items-center text-base font-semibold leading-tight text-chip-bg hover:text-chip-accent">
                                            Read more
                                            <svg aria-hidden="true" className="w-4 h-4 ml-2" fill="currentColor"
                                                 viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>
            </div>
        </div>

    )
}
export default Blog_detail_list;

