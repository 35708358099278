import Layout from "../Layout";
import Blog_detail_list from "./Blogpost_detail_list";

import Blog_hero from "./Blog_hero";

export default function Blog(){
    return (
        <Layout>
            <Blog_hero />
            <Blog_detail_list />
        </Layout>
    );
}
