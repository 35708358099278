
import React from 'react';

function Mission_statement_with_image() {
    return(
        <div className="relative isolate ">
            <svg
                className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-body-bg-end [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                        width={200}
                        height={200}
                        x="50%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-body-bg-end/80">
                    <path
                        d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
            </svg>
            <div className="overflow-hidden">
                <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                    <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                        <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-text-important sm:text-6xl">
                                Change the way you think about to-do lists
                            </h1>
                            <p className="relative mt-6 text-lg leading-8 text-text-bg sm:max-w-md lg:max-w-none">
                                Make it fun, make it rewarding. Taskz is the daily planner that helps you stay motivated and to achieve more. Take control of your life and goals and reduce stress with our intuitive planner and workload tracker.
                            </p>
                        </div>
                        <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                            <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                <div className="relative">
                                    <img
                                        src="Mission_combat.png"
                                        alt=""
                                        className="aspect-[2/3] w-full rounded-xl bg-text-bg/5 object-cover shadow-lg"
                                    />
                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-text-bg/10" />
                                </div>
                            </div>
                            <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                <div className="relative">
                                    <img
                                        src="Mission_goals.png"
                                        alt=""
                                        className="aspect-[2/3] w-full rounded-xl bg-text-bg/5 object-cover shadow-lg"
                                    />
                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-text-bg/10" />
                                </div>
                                <div className="relative">
                                    <img
                                        src="Mission_phoenix.png"
                                        alt=""
                                        className="aspect-[2/3] w-full rounded-xl bg-text-bg/5 object-cover shadow-lg"
                                    />
                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-text-bg/10" />
                                </div>
                            </div>
                            <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                <div className="relative">
                                    <img
                                        src="Mission_quest.png"
                                        alt=""
                                        className="aspect-[2/3] w-full rounded-xl bg-text-bg/5 object-cover shadow-lg"
                                    />
                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-text-bg/10" />
                                </div>
                                <div className="relative">
                                    <img
                                        src="Mission_timebox.png"
                                        alt=""
                                        className="aspect-[2/3] w-full rounded-xl bg-text-bg/5 object-cover shadow-lg"
                                    />
                                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-text-bg/10" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Mission_statement_with_image;

