import React, {useEffect, useState} from 'react';
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetFeaturedPosts} from "../../lib/BlogApi";
import {format} from "date-fns";

function About_blog() {
    const [posts, setPosts] = useState([])
    const supabase = useSupabaseClient()
    useEffect(() => {
        async function fetchBlog(){
            var posts = await apiGetFeaturedPosts(supabase)
            setPosts(posts)
        }
        fetchBlog()
    },[])
    return(
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8 items-center justify-center">
            <div className="mx-auto lg:mx-0 lg:max-w-none text-center">
                <h2 className="text-3xl font-bold tracking-tight text-text-bg sm:text-4xl">From the blog</h2>
                <p className="mt-2 text-lg leading-8 text-text-bg">

                </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl gap-8 sm:mt-20 lg:mx-0 lg:max-w-none flex items-center justify-center">
                {posts.map((post) => (
                    <article
                        key={post.id}
                        className="relative isolate max-w-sm flex flex-col justify-end overflow-hidden rounded-2xl bg-card-bg px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                    >
                        <img src="blog_bg.png" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-text-bg/40" />
                        <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-text-bg/10" />

                        <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-blog-text-sub-bg">
                            <time dateTime={post.created_at} className="mr-8">
                                {format(new Date(post.created_at),"MMMM dd, yyyy")}
                            </time>
                            <div className="-ml-4 flex items-center gap-x-4">
                                <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                    <circle cx={1} cy={1} r={1} />
                                </svg>
                                <div className="flex gap-x-2.5">
                                    <img src={post.author_image} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                                    {post.author_name}
                                </div>
                            </div>
                        </div>
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-blog-text-bg">
                            <a href={"/blog/post/"+post.id}>
                                <span className="absolute inset-0" />
                                {post.title}
                            </a>
                        </h3>
                    </article>
                ))}
            </div>
        </div>
    )
}
export default About_blog;
