
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing page/Landing";
import About from "./components/About/About";
import Blog from "./components/Blog/Blog";
import Blogpost from "./components/Blog/Blogpost";
import Legal from "./components/Legal/Legal";
import Privacy from "./components/Legal/Privacy";
import Terms from "./components/Legal/Terms";
import Cookies from "./components/Legal/Cookies";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog/post/:id" element={<Blogpost />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsofservice" element={<Terms />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/*" element={<Landing />} />
        </Routes>
      </Router>
  );
}

export default App;
