import React, {useEffect, useState} from 'react';
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {apiGetFeatured, apiGetHero} from "../../lib/BlogApi";
import {format} from "date-fns";

function Blog_hero() {
    const [hero,setHero] = useState([])
    const [featured,setFeatured] = useState([])
    const supabase = useSupabaseClient()

    useEffect(() => {
        async function fetchHero(){
            var hero = await apiGetHero(supabase)
            setHero(hero)
        }
        async function fetchFeatured(){
            var featured = await apiGetFeatured(supabase)
            setFeatured(featured)
        }
        fetchHero()
        fetchFeatured()
    },[])
return(
    <section className="border-t border-b border-text-bg bg-card-bg">
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24 pt-20 sm:pt-24 lg:pt-32">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-20">
                {hero.map((post) => (

                    <article>

                        <div className="mt-5 space-y-4">
                            <h2 className="text-2xl font-bold leading-tight tracking-tight text-text-bg hover:underline">
                                <a href={"/blog/post/"+post.id} title="">
                                    {post.title}
                                </a>
                            </h2>

                            <div className="flex items-center gap-3">
                                <img className="w-8 h-8 rounded-full"
                                     src={post.author_image}
                                     alt=""/>
                                    <div className="text-lg font-medium leading-tight text-text-bg">
                                        <div>
                                            {post.author_name}
                                        </div>
                                        <div className="text-sm font-normal text-text-bg/80">
                                            Posted on {format(new Date(post.created_at),"MMMM dd, yyyy")}
                                        </div>
                                    </div>
                            </div>

                            <p className="text-base font-normal text-text-bg">
                                {post.description}
                            </p>

                            <a href={"/blog/post/"+post.id} title=""
                               className="inline-flex items-center text-base font-semibold leading-tight text-chip-bg hover:text-chip-accent">
                                Read more
                                <svg aria-hidden="true" className="w-4 h-4 ml-2" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </article>
                ))
                }
                <div className="space-y-8">
                    {featured.map((post) => (
                        <article>
                            <div className="space-y-4">

                                <h2 className="text-2xl font-bold leading-tight tracking-tight text-text-bg hover:text-chip-bg">
                                    <a href={"/blog/post/"+post.id} title="">
                                        {post.title}
                                    </a>
                                </h2>

                                <p className="text-base font-normal text-text-bg">
                                    {post.description}
                                </p>

                                <a href={"/blog/post/"+post.id} title=""
                                   className="inline-flex items-center text-base font-semibold leading-tight text-text-bg hover:text-chip-bg">
                                    Read more
                                    <svg aria-hidden="true" className="w-4 h-4 ml-2" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                              clipRule="evenodd"></path>
                                    </svg>
                                </a>
                            </div>
                        </article>
                        ))
                    }
                </div>
            </div>
        </div>
    </section>

    )
}
export default Blog_hero;
